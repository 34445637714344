<template>
  <v-form :readonly="value.id > 0 && !canEditSetupTables">
    <v-container>
      <v-row>
        <v-col cols="12" md="12" class="px-0">
          <v-btn @click="cancel(false)"> Cancel </v-btn>
          <v-btn v-if="canEditSetupTables" class="float-right" color="green" dark @click="onSubmit"> save </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-expansion-panels accordion mandatory>
      <v-expansion-panel>
        <v-expansion-panel-header>General</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12" md="10">
              <v-text-field
                :value="value.name"
                v-model.trim="$v.form.name.$model"
                label="Name"
                required
                :error-messages="handleErrors($v.form.name)"
                @blur="$v.form.name.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="5">
              <v-text-field
                :value="value.email"
                v-model.trim="$v.form.email.$model"
                label="Agency Email"
                required
                @blur="$v.form.email.$touch()"
                :readonly="value.readonly"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>Address</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <address-form
              ref="aform"
              :value="address"
              :showLatLng="false"
              :required="false"
              @input="handleInput"
            ></address-form>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import AddressForm from '@/components/AddressForm.vue';
import { handleErrors } from '@/util';

const newRentalDealership = {
  type: 'rental',
  name: '',
  email: '',
  phone: '',
  contactName: '',
  address: {},
  active: true,
};

export default {
  mixins: [validationMixin],
  components: { AddressForm },
  data: () => ({
    handleErrors,
    panel: [0],
    id: null,
    form: newRentalDealership,
    address: {},
    search: null,
    saved: false,
  }),
  validations: {
    form: {
      type: {},
      name: { required },
      email: { required },
      phone: {},
      contactName: {},
    },
  },
  computed: {
    ...mapGetters('user', ['me', 'canEditSetupTables']),
  },
  methods: {
    async cancel(fromSave) {
      let ok = true;
      const changesMade = this.compareValues();
      if (!fromSave && changesMade)
        ok = await this.$myconfirm('Are you sure you want to close this form? Your changes will not be saved.');
      if (ok) {
        this.form = newRentalDealership;
        if (this.$refs.aform) this.$refs.aform.clear();
        this.$emit('closeForm');
      }
    },
    handleInput(address) {
      this.address = address;
    },
    onSubmit() {
      if (this.$v.form.$invalid) {
        this.$v.form.$touch();
        this.$myalert.error('Please complete all required fields');
        return;
      }
      if (
        (this.address.address || this.address.city || this.address.state || this.address.zip) &&
        !(this.address.address && this.address.city && this.address.state && this.address.zip)
      ) {
        this.$myalert.error('Please complete all address fields');
        return;
      }
      this.$emit('submit', { id: this.id, ...this.$v.form.$model, address: this.address });
      this.saved = true;
    },
    compareValues() {
      if (!this.id && (this.$v.form.$model.code || this.$v.form.$model.name)) return true;
      if (!this.id) return false;
      if (
        this.$v.form.$model.name != this.value.name ||
        this.$v.form.$model.email != this.value.email ||
        this.$v.form.$model.phone != this.value.phone ||
        this.$v.form.$model.contactName != this.value.contactName ||
        JSON.stringify(this.address) != JSON.stringify(this.value.address || {})
      )
        return true;
      return false;
    },
  },
  props: {
    value: {
      type: Object,
      default: () => newRentalDealership,
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.id = value.id;
        if (value.id && this.saved) {
          this.saved = false;
          this.cancel(true);
        } else {
          this.form = { ...newRentalDealership, ...value };
          this.address = value.address ? value.address : {};
        }
      },
    },
  },
};
</script>
